import { login } from "@/api/login";
import { createStore } from "vuex";
import { setUserInfo, getUserInfo, removeUserInfo } from '@/utils/auth'
import router from "../router";


export default createStore({
  state: {
    pageTitle: '',
    info: null,
    maps: ''
  },
  getters: {
    // getName: (state) => {
    //   return state.Name;
    // }
  },
  mutations: {
    SET_PAGE_TITLE(state, title) {
      state.pageTitle = title
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    LOGIN_OUT: (state, info) => {
      state.info = ''
    },
    SET_MAPS: (state, maps) => {
      // console.log(maps, 'mapsmapsmapsmapsmapsmaps')
      state.maps = JSON.stringify(maps)
    }
  },

  actions: {
    setPageTitle({ commit }, pageTitle) {
      console.log(pageTitle)
      commit('SET_PAGE_TITLE', pageTitle)
    },
    setUserInfo({ commit }, info) {
      setUserInfo(JSON.stringify(info))
      commit('SET_INFO', info)
    },
    loginOut({ commit }) {
      // console.log(info)
      removeUserInfo()
      commit('LOGIN_OUT')
      setTimeout(() => {
        router.push('/')
      }, 500)
    },
    setMaps({ commit }, maps) {
      commit('SET_MAPS', maps)
    },
  },
  modules: {},
});
