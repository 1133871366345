<!-- eslint-disable prettier/prettier -->
<template>
  <div class="home">
    <el-row :gutter="10" class="marginStyle">
      <el-col :xs="24" :sm="24" :md="5" class="cardBig">
        <el-card style="min-height: 800px;">
          <div style="display: flex;align-items: center;">
            <span style="background: #409eff;width: 4px;height: 30px;"></span>
            <span style="font-size: 24px;margin-left: 10px;font-family:MicrosoftYaHei;">答题卡</span>
          </div>
          <div class="itemBox">
            <div
              @click="changeIndex(index)"
              v-for="(item, index) in recordArr"
              :key="index"
              :class="{
                card_item: true,
                checked:questionsIndex == index
              }"
            >
              <img v-if="item.upBool&&item.answer" src="../assets/finished.png" class="finish" alt />
              {{ index+1 }}
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="16" style="position: relative;">
        <el-card style="min-height: 800px;">
          <el-col span="24" class="cardSmall" v-if="recordArr.length">
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div style="text-align: left;">
                <div>
                  <span style="font-size: 12px;color: #a3a3a3;">剩余时间：</span>
                  <span style="color: #FA6400;font-weight: 600;font-size: 14px;">{{ timeNumTxt }}</span>
                </div>
                <div>
                  <span style="font-size: 12px;color: #a3a3a3;margin-top: 10px;">已答题数：</span>
                  <span
                    style="font-weight: 500;font-size: 14px;"
                  >{{recordArr.filter(e=>e.answer).length }}/{{ recordArr.length }}</span>
                </div>
              </div>
              <div style="display: flex;align-items: center;">
                <el-button
                  v-if="questionsIndex < recordArr.length-1"
                  type="primary"
                  size="small"
                  @click="nextClick"
                >下一题</el-button>
                <el-button
                  v-if="questionsIndex ==recordArr.length-1"
                  type="primary"
                  size="small"
                  @click="recordSubmit"
                >提交试卷</el-button>
                <el-button type="text" @click="dialogVisible = true">答题卡</el-button>
              </div>
            </div>
          </el-col>
          <div
            v-if="recordArr.length&&recordArr[questionsIndex].upBool&&recordArr[questionsIndex].answer"
            class="finishBox"
          ></div>
          <div v-if="questionsArr.length">
            <div
              class="questionTxt"
              id="quesMath"
            >第{{ questionsIndex+1}}题.{{questionsArr[questionsIndex].content}}</div>
            <img
              style="transform: scale(0.8);margin-bottom: -30px;margin-top: -8px;"
              class="quesImg"
              v-if="questionsArr[questionsIndex].img"
              :src="returnImgUrl(questionsArr[questionsIndex].img)"
              alt
            />
            <div
              style="margin-top: 30px;padding:0 30px;box-sizing: border-box;"
              :class="{imgflexbox:questionsArr[questionsIndex].options[0].img?true:false}"
            >
              <div
                class="imgflexin"
                @click="upDateQuestions(item.no)"
                style="margin-bottom: 20px;"
                size="large"
                v-for="(item, index) in questionsArr[questionsIndex].options"
                :key="index"
                :value="item.no"
              >
                <div
                  :class="{
                    forBox: true,
                    isCheck:radio ==item.no?true:false
              }"
                >
                  <div
                    :style="`${item.no==radio?'background:#409eff !important;':'background: #cccccc;'}`"
                    style="width: 18px;height: 18px;border-radius: 100%;position: relative;"
                  >
                    <!-- v-if="item.no==radio" -->
                    <img
                      style="position: absolute;width: 16px;left: 1px;top: 1px;"
                      src="../assets/check.png"
                      alt
                    />
                  </div>
                  <div style="margin-right: 10px;font-weight: 500;">（{{ radioArr[index] }}）</div>
                  <div id="answerMathIn" v-if="item.value" class="forBox">{{ item.value }}</div>
                  <img style="max-width: 160px;" v-if="item.img" :src="returnImgUrl(item.img)" alt />
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex;justify-content: center;" class="cardBig">
            <el-button
              v-if="recordArr.length&&questionsIndex<recordArr.length-1"
              type="primary"
              @click="nextClick"
            >下一题</el-button>
            <el-button
              v-if="questionsIndex ==recordArr.length-1"
              type="primary"
              @click="recordSubmit"
            >提交试卷</el-button>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="3" class="cardBig">
        <el-card>
          <div style="font-size: 14px;color: #a3a3a3;">剩余时间</div>
          <div style="color: #FA6400;font-weight: 600;font-size: 18px;">{{ timeNumTxt }}</div>
          <div style="font-size: 14px;color: #a3a3a3;margin-top: 10px;">已答题数</div>
          <div
            style="font-weight: 600;font-size: 20px;"
          >{{recordArr.filter(e=>e.answer).length }}/{{ recordArr.length }}</div>
        </el-card>
      </el-col>
    </el-row>
  </div>
  <el-dialog v-model="dialogVisible" width="90%" :before-close="handleClose">
    <div class="itemBox" style="display: flex;justify-content: space-between;align-items: center;">
      <div
        @click="changeIndex(index)"
        v-for="(item, index) in recordArr"
        :key="index"
        :class="{
                card_item: true,
                checked:questionsIndex == index
              }"
      >
        <img v-if="item.upBool&&item.answer" src="../assets/finished.png" class="finish" alt />
        {{ index+1 }}
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  ref,
  onMounted,
  nextTick,
  getCurrentInstance,
  onBeforeUnmount,
  computed
} from 'vue'
import {
  recordStartApi,
  recordAnswerApi,
  recordSubmitApi,
  recordResultApi
} from '@/api/test'
import { useRoute, useRouter } from 'vue-router'
import { getObsUrl } from '../utils/obsUrl'
import { ElMessage, ElMessageBox } from 'element-plus'

// import dayjs from 'dayjs'

export default {
  setup() {
    // https://hyexam.obs.cn-east-3.myhuaweicloud.com/
    const { proxy } = getCurrentInstance()

    // const $dayjs = instance?.appContext.config.globalProperties.$dayjs

    const route = useRoute()
    const router = useRouter()
    const dialogVisible = ref(false)

    let questionsArr = ref([])
    let recordArr = ref([])
    let historyAnswer = ref('')
    let questionsIndex = ref(0)
    let radioArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
    let radio = ref('')
    const todetail = route.query.todetail
    console.log(todetail)
    const groupId = route.query.group
    const _id = route.query._id
    // const quesRef = ref(null)
    // const answerRef = ref(null)
    const recordId = ref('')
    recordId.value = route.query.recordId

    const timeNum = ref(0)
    let timer
    let timeNumTxt = computed((i) => {
      const hours =
        Math.floor((timeNum.value % (60 * 60 * 24)) / (60 * 60)) || 0
      const minutes = Math.floor((timeNum.value % (60 * 60)) / 60) || 0
      const seconds = Math.floor(timeNum.value % 60) || 0

      return `${hours < 10 ? '0' + hours : hours}: ${
        minutes < 10 ? '0' + minutes : minutes
      }: ${seconds < 10 ? '0' + seconds : seconds}`
    })

    const continueConfirmedBool = ref()
    continueConfirmedBool.value = route.query.continueConfirmedBool
      ? true
      : false
    const changeIndex = (i) => {
      dialogVisible.value = false
      questionsIndex.value = i
      radio.value = ''
      const arr = recordArr.value
      if (arr[i].answer && arr[i].upBool) {
        radio.value = arr[i].answer
      } else {
        radio.value = ''
      }
      upMathJax()
    }
    const upMathJax = (item) => {
      nextTick(() => {
        MathJax.texReset()
        MathJax.typesetClear()
        MathJax.typesetPromise()
      })
    }
    const upDateQuestions = (value) => {
      radio.value = value
      const arr = recordArr.value
      const i = questionsIndex.value
      recordAnswerApi({
        _id: recordId.value, // 记录ID
        question: arr[i].question, // 问题ID
        answer: value // 回答
      }).then((res) => {
        if (res.success) {
          arr[i] = Object.assign({}, arr[i], {
            upBool: true,
            answer: value
          })
        }
      })
    }
    const nextClick = (item) => {
      const i = questionsIndex.value + 1
      changeIndex(i)
    }
    const recordSubmit = (item) => {
      const arr = recordArr.value
      const i = questionsIndex.value
      recordSubmitApi({
        _id: recordId.value, // 记录ID
        question: arr[i].question, // 问题ID
        answer: radio.value // 回答
      }).then((res) => {
        if (res.success) {
          router.replace({
            name: todetail ? 'resultdetail' : 'result',
            query: { _id: recordId.value }
          })
        }
        console.log(res, 'resres')
      })
    }

    // const continueFun = () => {
    //   ElMessageBox.confirm(
    //     '上一次测验尚未结束，是否继续未结束的测验？',
    //     '提示',
    //     {
    //       confirmButtonText: '继续开始',
    //       cancelButtonText: '重新开始',
    //       type: 'warning'
    //     }
    //   )
    //     .then(() => {
    //       continueConfirmedBool.value = true
    //       testInit()
    //     })
    //     .catch(() => {
    //       continueConfirmedBool.value = false
    //       testInit()
    //     })
    // }
    const testInit = (item) => {
      clearInterval(timer)
      recordStartApi({
        group: groupId,
        _id: _id
      }).then((res) => {
        if (res.success) {
          if (res.result.record.submitAt) {
            router.push({
              name: todetail ? 'resultdetail' : 'result',
              query: { _id: res.result.record._id }
            })
            return false
          }
          const interval =
            res.result.duration -
            (+proxy.$dayjs() - +proxy.$dayjs(res.result.record.startAt)) / 1000
          timeNum.value = interval < 1 ? 0 : interval
          questionsArr.value = res.result.questions
          recordArr.value = res.result.record.answers.map((e) => {
            return Object.assign({}, e, {
              upBool: e.answer ? true : false
            })
          })
          radio.value = recordArr.value[questionsIndex.value].answer
          recordId.value = res.result.record._id
          upMathJax()
          console.log(interval, timeNum.value, 'timeNumtimeNumtimeNumtimeNum')
          if (timeNum.value > 0) {
            timer = setInterval(() => {
              if (timeNum.value < 1) {
                clearInterval(timer)
                ElMessage.error('考试结束，自动提交考卷')
                setTimeout(() => {
                  recordSubmit()
                }, 500)
              } else {
                timeNum.value = timeNum.value - 1
              }
            }, 1000)
          }
        }
        if (res.error && res.error.code == 'error.record_exists') {
          // ElMessage.error('测试已结束')
          // router.back()
          router.replace({
            name: 'home'
          })
        }
        // console.log(res)
      })
    }

    onMounted(() => {
      testInit()
    })
    onBeforeUnmount(() => {
      clearInterval(timer)
    })
    const returnImgUrl = (url) => {
      return getObsUrl(url)
    }
    return {
      // quesRef,
      // answerRef,
      dialogVisible,
      timeNum,
      timeNumTxt,
      timer,
      radio,
      radioArr,
      questionsArr,
      questionsIndex,
      recordArr,
      returnImgUrl,
      changeIndex,
      upDateQuestions,
      nextClick,
      recordSubmit
    }
  }
}
</script>

<style lang="less" scoped>
.imgflexbox {
  display: flex;
  flex-wrap: wrap;
  .imgflexin {
    width: 50%;
  }
}
.isCheck {
  color: #409eff;
  font-weight: 600;
}
.forBox {
  display: flex;
  align-items: center;
  font-size: 19px;
  margin-bottom: 2px;
  cursor: pointer;
  // font-weight: 600;
}
.finishBox {
  width: 30px;
  height: 30px;
  left: 5px;
  top: 0;
  position: absolute;
  background: #409eff;
  clip-path: polygon(0 0, 100% 0, 0 100%, 0 0);
}

.marginStyle {
  // margin-left: -20px;
  // margin-right: -20px;
}
.cardSmall {
  display: none;
}
.cardBig {
  display: block;
}
.control {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    width: 30px;
    height: 30px;
  }
}
.noCard {
  margin-top: 20px;
  display: flex;
  align-items: start;
  flex-direction: column;
}
.itemBox {
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 14px;
  .card_item {
    margin: 8px 4.8px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #f0f3fa;
    font-size: 18px;
    position: relative;
    font-weight: 800;
  }
  .finish {
    top: -3px;
    left: 0px;
    width: 15px;
    height: 15px;
    position: absolute;
  }
}
.questionTxt {
  white-space: pre-wrap !important;
  margin-top: 20px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  font-size: 20px;
}
.answerBox {
}
.home {
  margin: 0 auto;
  width: 1200px;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  background: #333333;
}
.checked {
  background: #d2ddf5 !important;
}
body {
  background: #eff3f8;
  margin: 0;
  padding: 0;
}
.quesImg {
  max-width: 650px;
}
</style>
<style lang="less">
@media (max-width: 1000px) {
  .quesImg {
    max-width: 650px !important;
    transform: scale(0.9) !important;
  }
  .home {
    width: 100% !important;
  }
  .cardBig {
    display: none !important;
  }
  .cardSmall {
    display: block !important;
  }
  .marginStyle {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (max-width: 700px) {
  .imgflexbox {
    display: flex;
    flex-wrap: wrap;
    .imgflexin {
      width: 100% !important;
    }
  }
  .quesImg {
    max-width: 380px !important;
    transform: scale(0.9) !important;
  }
}
</style>




