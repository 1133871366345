import request from '@/utils/request'


export function recordStartApi(data) {
  return request({
    url: '/record/start',
    method: 'post',
    data
  })
}
export function recordAnswerApi(data) {
  return request({
    url: '/record/answer',
    method: 'post',
    data
  })
}

export function recordSubmitApi(data) {
  return request({
    url: '/record/submit',
    method: 'post',
    data
  })
}

export function recordResultApi(query) {
  return request({
    url: '/record/result',
    method: 'GET',
    params: query
  })
}

// export function paperlist() {
//   return request({
//     url: '/paper/group/list',
//     method: 'GET',
//   })
// }
// export function userDetail(query) {
//   return request({
//     url: '/user/detail',
//     method: 'get',
//     params: query
//   })
// }
// export function login(query) {
//   return request({
//     url: '/user/login',
//     method: 'get',
//     params: query
//   })
// }

