import request from '@/utils/request'


export function login(data) {
  return request({
    url: '/user/login',
    method: 'post',
    data
  })
}
export function getCode(data) {
  return request({
    url: '/helper/mobile-verify',
    method: 'post',
    data
  })
}
// 获取字典数据
export function getDictionary() {
  return request({
    url: '/helper/maps',
    method: 'get',
  })
}
//

// export function login(query) {
//   return request({
//     url: '/user/login',
//     method: 'get',
//     params: query
//   })
// }


// export function alarmHandle(data) {
//   return request({
//     url: '/alarm/handle',
//     method: 'post',
//     data
//   })
// }
