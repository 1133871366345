window.MathJax = {
  tex: {
    inlineMath: [
      ["$", "$"],
      // ["\\(", "\\)"],
    ], // 行内公式选择符
    displayMath: [
      ["$$", "$$"],
      // ["\\[", "\\]"],
    ], // 段内公式选择符

  },
  options: {
    skipHtmlTags: ['script', 'noscript', 'style', 'textarea', 'code', 'a'],
  },
  startup: {
    ready() {
      MathJax.startup.defaultReady();
    },
  },
};