<!-- eslint-disable prettier/prettier -->
<template>
  <div style="margin-top: 40px;">
    <el-row style="display: flex;align-items: center;justify-content: center;">
      <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="20">
        <!-- <el-card > -->
        <div class="box" style="min-height: 800px;">
          <el-row :gutter="30">
            <el-col
              style="margin-bottom: 20px;"
              v-for="(item, index) in paperLists"
              :key="index"
              :xs="24"
              :sm="12"
              :md="12"
              :lg="8"
              :xl="6"
            >
              <div v-if="item.name" class="type_card">
                <div :style="`background:${bgcolor}`" class="card_left">
                  <img v-if="bgimg=='../assets/history.png'" src="../assets/history.png" alt />
                  <img v-if="bgimg=='../assets/running.png'" src="../assets/running.png" alt />
                  {{ item.group.name }}
                  {{ item.name }}
                </div>
                <div class="card_right">
                  <el-button v-if="!item.record" @click="toAmcTest(item)" type="primary">开始评估</el-button>
                  <el-button
                    v-if="item.record&&!item.record.submitAt"
                    type="primary"
                    @click="toContinueResult(item)"
                  >继续评估</el-button>
                  <div>
                    <el-button
                      v-if="item.record&&item.record.submitAt"
                      type="primary"
                      @click="toAmcResult(item)"
                    >查看结果</el-button>
                    <!-- <el-button type="primary"></el-button> -->
                  </div>
                  <div>
                    <el-button
                      v-if="item.record&&item.record.submitAt"
                      type="primary"
                      @click="toAmcTest(item)"
                    >再来一次</el-button>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- </el-card> -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Refresh } from '@element-plus/icons-vue'
import { ref, onMounted, nextTick, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import { setUserInfo, getUserInfo, removeUserInfo } from '@/utils/auth'
import { userUpDate, userDetail } from '@/api/user'
import { login } from '@/api/login'
import { paperlists } from '@/api/home'

import { useStore } from 'vuex'
import { getDictionary } from '@/api/login'
import { useRouter, useRoute } from 'vue-router'

export default {
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const paperLists = ref([])

    const toContinueResult = (item) => {
      router.push({
        name: 'test',
        query: { _id: item._id, todetail: true, recordId: item.record._id }
      })
    }
    const toAmcResult = (item) => {
      router.push({
        name: 'resultdetail',
        query: { _id: item.record._id }
      })
    }
    const toAmcTest = (item) => {
      // router.push({ name: 'result' })
      // console.log()
      // return false
      if (store.state.info) {
        console.log()
        if (store.state.info.gender) {
          router.push({
            name: 'test',
            query: { _id: item._id, todetail: true }
          })
        } else {
          router.push({ name: 'user' })
        }
      } else {
        ElMessage.error('请先登录')
        setTimeout(() => {
          router.push('/login')
        }, 500)
      }
    }

    onMounted(() => {
      console.log(route.query)
      paperlists({ group: route.query.group }).then((res) => {
        if (res.success) {
          paperLists.value = res.result
        }
      })
    })
    // imgurl: require('../static/images/19_222949_14.png')

    return {
      bgcolor: route.query.bgcolor,
      bgimg: route.query.bgimg,
      toContinueResult,
      toAmcResult,
      toAmcTest,
      paperLists
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  padding: 20px 0;
  box-sizing: border-box;
}
.type_card:hover {
  cursor: pointer;
}
.type_card {
  position: relative;
  width: 100%;
  height: 120px;
  border-radius: 16px;
  font-size: 22px;
  text-align: center;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* 水平偏移 垂直偏移 模糊半径 颜色 */
  .card_left {
    color: #fff;
    flex: 3;
    line-height: 120px;
  }
  .card_right {
    padding: 20px 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex: 1.3;
    background: #ffffff;
  }
  img {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  // .btn {
  //   position: absolute;
  //   right: 10px;
  //   bottom: 2px;
  // }
}

@media (max-width: 600px) {
  .type_card {
    font-size: 18px !important;
  }
}
</style>




