import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import ElementPlus from "element-plus";
import mathjax from './utils/mathjax.js'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import "mathjax/es5/tex-svg"; // 使用 tex-svg.js
// import "mathjax/es5/tex-mml-chtml"; // 使用 tex-mml-chtml 

// import getObsUrl from './utils/obsUrl.js'
import * as dayjs from 'dayjs'



import "element-plus/dist/index.css";
// import { useStore } from 'vuex';
import { setUserInfo, getUserInfo, removeUserInfo } from '@/utils/auth'

// console.log(getUserInfo())
router.beforeEach((to, from, next) => {
  const userInfo = getUserInfo()

  if (userInfo) {
    store.dispatch('setUserInfo', JSON.parse(getUserInfo()))
    if (to.path === '/login') {
      next({ path: '/' })
    }
    next()
    // next(`/?redirect=${to.path}`)

  } else {
    if (to.path === '/login' || to.path === '/') {
      next()
    } else {
      console.log(111)
      // alert(1)
      next({ path: '/' })
      // next()

      // return next(`/?redirect=${to.path}`)

      // 跳转到首页
    }
  }

  if (to.meta.title) {
    document.title = to.meta.title;
    store.dispatch('setPageTitle', to.meta.title);
  } else {
    document.title = '数学超哥';
    store.dispatch('setPageTitle', to.meta.title);


  }
  next();
});




// document.title = "数学超哥"
// createApp(App).use(store).use(router).use(ElementPlus).mount("#app");
const app = createApp(App);
app.config.globalProperties.$dayjs = dayjs
// app.config.globalProperties.$getObsUrl = getObsUrl
app.use(mathjax); // 使用 mathjax
app.use(ElementPlus); // 使用ElementPlus
app.use(ElementPlusIconsVue); // 使用ElementPlus
app.use(router); // 使用Vue Router
app.use(store); // 使用 Store

// app.use(dayjs); // 使用Vuex Store




// app.provide('$globalVariable', mathjax)

app.mount('#app'); // 挂载Vue应用程序到指定的元素