<!-- eslint-disable prettier/prettier -->
<template>
  <div style="margin-top: 80px;">
    <el-row style="display: flex;align-items: center;justify-content: center;">
      <el-col :xs="22" :sm="22" :md="16" :lg="8" :xl="8">
        <el-card
          style="padding: 50px 20px;display: flex;flex-direction: column;align-items: center;"
        >
          <div style="margin-bottom: 20px;font-size: 26px;font-weight: 500;">登录</div>
          <el-form
            size="large"
            :model="ruleForm"
            label-width="auto"
            style="max-width: 800px"
            :rules="rules"
            ref="formRef"
          >
            <el-form-item label="手机号" prop="mobile" style="width: 300px;">
              <el-input v-model="ruleForm.mobile" />
            </el-form-item>
            <div style="display: flex;">
              <el-form-item label="验证码" prop="verify" style="width: 180px;">
                <el-input maxlength="4" v-model="ruleForm.verify" />
              </el-form-item>
              <el-button
                type="primary"
                v-if="timeNum==60"
                style="margin-left: 10px;"
                @click="getCodeClick()"
              >获取验证码</el-button>
              <el-button
                v-if="timeNum < 60"
                style="margin-left: 10px;width: 110px;font-size: 16px;"
              >{{ timeNum}} 秒后重发</el-button>
            </div>

            <el-button
              style="margin:40px 10px;"
              type="primary"
              @click="submitForm(ruleFormRef)"
            >立即登录</el-button>
            <el-button style="margin:40px 10px;" type @click="backPage()">返回</el-button>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, reactive, onBeforeUnmount } from 'vue'
import { ElMessage } from 'element-plus'
import { login, getCode } from '@/api/login'
import { useStore } from 'vuex'
import { setUserInfo, getUserInfo, removeUserInfo } from '@/utils/auth'
import { useRouter } from 'vue-router'

export default {
  setup() {
    // console.log(JSON.parse(getUserInfo()))

    let store = useStore()
    const router = useRouter()

    // console.log(store.state.info, 1111)

    const locationOptions = ['Home', 'Company', 'School']
    const ruleForm = ref({
      mobile: '',
      verify: '',
      appId: 'web'
    })
    const formRef = ref(null)
    const validatePhone = (rule, value, callback) => {
      const reg = /^1[3-9]\d{9}$/
      if (!value) {
        return callback(new Error('手机号码不能为空'))
      }
      if (!reg.test(value)) {
        return callback(new Error('请输入正确的手机号码'))
      }
      callback()
    }
    const rules = {
      mobile: [
        { required: true, message: '手机号码不能为空', trigger: 'blur' },
        { validator: validatePhone, trigger: 'blur' }
      ],

      verify: [
        { required: true, message: '请输入验证码', trigger: 'blur' },
        {
          length: 4,
          message: '请输入4位验证码',
          trigger: 'blur'
        }
      ]
    }

    const timeNum = ref(60)
    let timer

    // const getCodeClick = () => {
    //   // ElMessage.success('修改成功')
    //   // ElMessage.error('错误')

    //   if (!ruleForm.value.mobile) {
    //     ElMessage.error('请输入手机号')
    //     return false
    //   }
    // }
    // const getCodeClick = async () => {
    //   try {
    //     // 验证多个字段，字段名以数组形式传递
    //     await formRef.validateFields(['mobile'])
    //     ElMessage.success('Validation succeeded!')
    //   } catch (error) {
    //     ElMessage.error('Validation failed')
    //   }
    // }
    const getCodeClick = () => {
      formRef.value.validateField(['mobile'], (valid) => {
        console.log(valid)
        if (valid) {
          getCode(ruleForm.value).then((res) => {
            if (res.success) {
              timer = setInterval(() => {
                if (timeNum.value == 0) {
                  timeNum.value = 60
                  clearInterval(timer)
                } else {
                  timeNum.value = timeNum.value - 1
                }
              }, 1000) // 1000毫秒，即1秒
              ElMessage.success('发送成功')
            }
          })
        }
      })
    }
    const submitForm = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          login(ruleForm.value).then((res) => {
            if (res.success) {
              ElMessage.success('登录成功')
              store.dispatch('setUserInfo', res.result)

              setTimeout(() => {
                router.push('/')
              }, 1000)
            }
          })
        } else {
          // ElMessage.error('表单验证失败')
          return false
        }
      })
    }
    const backPage = () => {
      router.back()
    }

    onMounted(() => {
      setTimeout(() => {}, 10)
    })
    onBeforeUnmount(() => {
      clearInterval(timer)
    })

    return {
      formRef,
      rules,
      timeNum,
      ruleForm,
      submitForm,
      getCodeClick,
      backPage
    }
  }
}
</script>

<style lang="less">
</style>




