<!-- eslint-disable prettier/prettier -->
<template>
  <div style="margin-top: 80px;">
    <el-row style="display: flex;align-items: center;justify-content: center;">
      <el-col :xs="22" :sm="22" :md="10" :lg="10" :xl="10">
        <el-card
          style="padding: 50px 20px;display: flex;flex-direction: column;align-items: center;"
        >
          <div style="margin-bottom: 20px;font-size: 26px;font-weight: 500;">完善个人信息</div>
          <el-form size="large" :model="ruleForm" label-width="auto" :rules="rules" ref="formRef">
            <el-form-item label="姓名" prop="name" style="max-width: 300px;">
              <el-input maxlength="10" v-model="ruleForm.name" />
            </el-form-item>
            <el-form-item label="学校" prop="school" style="width: 300px;">
              <el-input maxlength="25" v-model="ruleForm.school" />
            </el-form-item>
            <el-form-item label="年级" prop="grade" style="width: 300px;">
              <el-select v-model="ruleForm.grade" placeholder="请选择年级" clearable>
                <el-option
                  v-for="(item, index) in gradeOptions"
                  :key="index"
                  :label="item"
                  :value="index"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="性别" prop="gender" style="width: 300px;">
              <el-radio-group v-model="ruleForm.gender">
                <el-radio value="男">男 生</el-radio>
                <el-radio value="女">女 生</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-button style="margin: 20px;" type="primary" @click="submitForm(ruleFormRef)">保存</el-button>
            <el-button style="margin:20px;" type @click="backPage()">返回</el-button>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import { setUserInfo, getUserInfo, removeUserInfo } from '@/utils/auth'
import { userUpDate, userDetail } from '@/api/user'
import { login } from '@/api/login'
import { useStore } from 'vuex'
import { getDictionary } from '@/api/login'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const store = useStore()
    const router = useRouter()

    const gradeOptions = ref()
    const ruleForm = ref({
      name: '',
      school: '',
      grade: '',
      gender: ''
    })
    const formRef = ref(null)
    const validateUsername = (rule, value, callback) => {
      if (value.indexOf('用户') > -1) {
        callback(new Error('请输入真实姓名'))
      } else {
        callback()
      }
    }
    const rules = {
      name: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
      // { validator: validateUsername, trigger: 'blur' }

      school: [{ required: true, message: '请输入学校', trigger: 'blur' }],
      grade: [{ required: true, message: '请选择年级', trigger: 'change' }],
      gender: [{ required: true, message: '请选择性别', trigger: 'change' }]
    }
    const backPage = () => {
      router.back()
    }
    const submitForm = () => {
      formRef.value.validate((valid) => {
        if (valid) {
          userUpDate(ruleForm.value).then((res) => {
            if (res.success) {
              ElMessage.success('修改成功')
              login().then((ress) => {
                store.dispatch('setUserInfo', ress.result)
                nextTick(() => {
                  router.back()
                })
                // setUserInfo(JSON.stringify(ress.result))
              })
            }
          })
          // ElMessage.success('验证成功')
          // 处理提交逻辑
        } else {
          // ElMessage.error('表单验证失败')
          return false
        }
      })
    }

    onMounted(() => {
      getDictionary().then((res) => {
        if (res.success) {
          gradeOptions.value = res.result.grade
          // uni.setStorageSync('dictionary', res.result)
        }
      })
      userDetail(ruleForm).then((res) => {
        console.log(res.result.name)
        ruleForm.value = Object.assign({}, res.result, {
          grade: res.result.grade ? res.result.grade.value : '',
          name: res.result.name.indexOf('用户') > -1 ? '' : res.result.name
        })

        // ruleForm.value.name = '1111'
        // console.log(ruleForm.value)
      })
      setTimeout(() => {}, 10)
    })
    return {
      formRef,
      rules,
      ruleForm,
      submitForm,
      gradeOptions,
      backPage
    }
  }
}
</script>

<style lang="less">
</style>




