import request from '@/utils/request'


export function paperlist() {
  return request({
    url: '/paper/group/list',
    method: 'GET',
  })
}
export function paperlists(query) {
  return request({
    url: '/paper/list',
    method: 'GET',
    params: query
  })
}
// export function userDetail(query) {
//   return request({
//     url: '/user/detail',
//     method: 'get',
//     params: query
//   })
// }
// export function login(query) {
//   return request({
//     url: '/user/login',
//     method: 'get',
//     params: query
//   })
// }


// export function alarmHandle(data) {
//   return request({
//     url: '/alarm/handle',
//     method: 'post',
//     data
//   })
// }
