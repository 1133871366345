<!-- eslint-disable prettier/prettier -->
<template>
  <div class="home">
    <el-row :gutter="10" class="marginStyle">
      <el-col :xs="24" :sm="24" :md="5" class="cardBig">
        <el-card style="min-height: 800px;">
          <div style="display: flex;align-items: center;">
            <span style="background: #409eff;width: 4px;height: 30px;"></span>
            <span style="font-size: 24px;margin-left: 10px;font-family:MicrosoftYaHei;">答题卡</span>
          </div>
          <div class="itemBox" v-if="recordArr.length">
            <div
              @click="changeIndex(index)"
              v-for="(item, index) in recordArr"
              :key="index"
              :class="{
                card_item: true,
                checked:questionsIndex == index
              }"
            >
              <img v-if="isRight(index)" src="../assets/yes.png" class="finish" alt />
              <img v-if="!isRight(index)" src="../assets/no.png" class="finish" alt />
              {{ index+1 }}
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="16">
        <el-card style="min-height: 800px;">
          <el-col span="24" class="cardSmall">
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <div style="text-align: left;" v-if="resultObj">
                <div>
                  <span style="font-size: 12px;color: #a3a3a3;">得分：</span>
                  <span
                    style="font-size: 20px;color: #67c23a;margin-right: 20px;font-weight: 500;"
                  >{{ resultObj.record.score }}</span>
                  <!-- </div>
                  <div>-->
                  <span style="font-size: 12px;color: #a3a3a3;">总分：</span>
                  <span style="font-size: 20px;">{{ resultObj.score }}</span>
                </div>
              </div>
              <div style="display: flex;align-items: center;">
                <el-button size="small" type="primary" @click="nextClick">下一题</el-button>
                <el-button type="text" @click="dialogVisible = true">答题卡</el-button>
              </div>
            </div>
          </el-col>
          <div v-if="questionsArr.length">
            <div
              class="questionTxt"
              id="quesMath"
            >第{{ questionsIndex+1}}题. {{questionsArr[questionsIndex].content}}</div>
            <img
              class="quesImg"
              style="transform: scale(0.8);margin-bottom: -30px;margin-top: -8px;"
              v-if="questionsArr[questionsIndex].img"
              :src="returnImgUrl(questionsArr[questionsIndex].img)"
              alt
            />
            <div
              style="margin-top: 30px;padding:0 30px;box-sizing: border-box;"
              :class="{imgflexbox:questionsArr[questionsIndex].options[0].img?true:false}"
            >
              <div
                class="imgflexin"
                v-for="(item, index) in questionsArr[questionsIndex].options"
                :key="index"
                :class="{
                  forBox: true,
                  isYes:isYes(item),
                  isNo:isYes(item)?false:isNo(item),
                }"
                style="margin-bottom: 20px;"
              >
                <div style="margin-right: 10px;font-weight: 500;">（{{ radioArr[index] }}）</div>
                <div id="answerMath" class="forBox">{{ item.value }}</div>
                <img style="max-width: 160px" v-if="item.img" :src="returnImgUrl(item.img)" alt />
              </div>
            </div>
          </div>
          <div style="margin-top: 30px;">
            <div v-if="recordArr.length&&!isRight(questionsIndex)" style="text-align: left;">
              选择：
              <span
                :style="isRight(questionsIndex) ? 'color:#67c23a' : 'color:red'"
                style="font-size: 18px;font-weight: 500;"
              >{{ getCheckIndex(questionsIndex) }}</span>
            </div>
            <div v-if="recordArr.length" style="text-align: left;">
              正确答案：
              <span
                style="color: #67c23a;font-size: 28px;font-weight: 500;"
              >{{ getRightIndex(questionsIndex) }}</span>
            </div>
            <div
              v-if="recordArr.length&&questionsArr[questionsIndex].analysis"
              style="align-items: center;text-align: left"
              id="detailMath"
            >解析:{{ questionsArr[questionsIndex].analysis }}</div>
          </div>
          <div style="margin-top: 30px;display: flex;justify-content: center;">
            <el-button class="cardBig" size="large" type="primary" @click="toHome">返回</el-button>
            <el-button class="cardSmall" size="small" type="primary" @click="toHome">返回</el-button>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="3" class="cardBig">
        <el-card>
          <div v-if="resultObj" style="font-weight: 500;text-align: left;">
            <div>
              得分：
              <span style="font-size: 30px;color: #67c23a;">{{ resultObj.record.score }}</span>
            </div>
            <div>
              总分：
              <span style="font-size: 26px;">{{ resultObj.score }}</span>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
  <el-dialog v-model="dialogVisible" width="90%" :before-close="handleClose">
    <div class="itemBox" style="display: flex;justify-content: space-between;align-items: center;">
      <div
        @click="changeIndex(index)"
        v-for="(item, index) in recordArr"
        :key="index"
        :class="{
               card_item: true,
               checked:questionsIndex == index
              }"
      >
        <img v-if="isRight(index)" src="../assets/yes.png" class="finish" alt />
        <img v-if="!isRight(index)" src="../assets/no.png" class="finish" alt />
        {{ index+1 }}
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { ref, onMounted, nextTick, computed } from 'vue'
import {
  recordStartApi,
  recordAnswerApi,
  recordSubmitApi,
  recordResultApi
} from '@/api/test'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getObsUrl } from '../utils/obsUrl'

export default {
  setup() {
    const route = useRoute()
    const router = useRouter()
    const dialogVisible = ref(false)

    let questionsArr = ref([])
    let recordArr = ref([])
    let resultObj = ref(null)
    let questionsIndex = ref(0)
    let radioArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
    let radio = ref('')
    // const quesRef = ref(null)
    // const answerRef = ref(null)
    // const detailRef = ref(null)

    const recordId = route.query._id

    onMounted(() => {
      testInit()
    })
    const nextClick = (item) => {
      if (questionsIndex.value == questionsArr.value.length - 1) {
        changeIndex(0)
      } else {
        const i = questionsIndex.value + 1
        changeIndex(i)
      }
    }
    const changeIndex = (i) => {
      dialogVisible.value = false
      questionsIndex.value = i
      radio.value = ''
      const arr = recordArr.value
      radio.value = arr[i].answer
      upMathJax()
    }
    const upMathJax = (item) => {
      nextTick(() => {
        MathJax.texReset()
        MathJax.typesetClear()
        MathJax.typesetPromise()
      })
    }
    const getRightIndex = (i) => {
      let qArr = questionsArr.value
      let oArr = qArr[i].options.map((res) => res.no)
      let code = qArr[i].answer + ''

      if (oArr.indexOf(code) < 0) {
        return '未设置'
      } else {
        return radioArr[oArr.indexOf(code)]
      }
    }

    const getCheckIndex = (i) => {
      let qArr = questionsArr.value
      let rArr = recordArr.value
      let oArr = qArr[i].options.map((res) => res.no)
      let code = rArr[i].answer + ''

      if (oArr.indexOf(code) < 0) {
        return '未选择'
      } else {
        return radioArr[oArr.indexOf(code)]
      }
    }
    const isRight = (i) => {
      let qArr = questionsArr.value
      let rArr = recordArr.value
      if (qArr[i].answer == rArr[i].answer) {
        return true
      } else {
        return false
      }
    }

    const isYes = (e) => {
      let qArr = questionsArr.value
      let rArr = recordArr.value
      let i = questionsIndex.value
      if (e.no == qArr[i].answer) {
        return true
      } else {
        return false
      }
    }
    const isNo = (e) => {
      let qArr = questionsArr.value
      let rArr = recordArr.value
      let i = questionsIndex.value
      if (!rArr[i].answer) {
        return false
      } else {
        if (e.no != qArr[i].answer && rArr[i].answer == e.no) {
          return true
        } else {
          return false
        }
      }
    }
    const toHome = (i) => {
      router.back()
    }
    const testInit = (item) => {
      recordResultApi({
        _id: recordId
      }).then((res) => {
        if (res.success) {
          resultObj.value = res.result
          questionsArr.value = res.result.questions
          recordArr.value = res.result.record.answers.map((e) => {
            return Object.assign({}, e, {
              upBool: e.answer ? true : false
            })
          })
          radio.value = recordArr.value[questionsIndex.value].answer
          upMathJax()
        }
        if (res.error && res.error.code == 'error.record_ask_continue') {
        }
      })
    }
    const returnImgUrl = (url) => {
      return getObsUrl(url)
    }
    return {
      isYes,
      isNo,
      // detailRef,
      // quesRef,
      // answerRef,
      dialogVisible,
      getRightIndex,
      getCheckIndex,
      resultObj,
      isRight,
      radio,
      radioArr,
      questionsArr,
      questionsIndex,
      recordArr,
      toHome,
      returnImgUrl,
      nextClick,
      changeIndex
    }
  }
}
</script>

<style lang="less" scoped>
.imgflexbox {
  display: flex;
  flex-wrap: wrap;
  .imgflexin {
    width: 50%;
  }
}
.isYes {
  color: #67c23a;
}
.isNo {
  color: red;
}
.forBox {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}
.marginStyle {
  // margin-left: -20px;
  // margin-right: -20px;
}
.cardSmall {
  display: none;
}
.cardBig {
  display: block;
}
.control {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  img {
    width: 30px;
    height: 30px;
  }
}
.noCard {
  margin-top: 20px;
  display: flex;
  align-items: start;
  flex-direction: column;
}
.itemBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 14px;
  .card_item {
    margin: 8px 4.8px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #f0f3fa;
    font-size: 18px;
    position: relative;
    font-weight: 800;
  }

  .finish {
    top: -3px;
    left: 0px;
    width: 15px;
    height: 15px;
    position: absolute;
  }
}
.checked {
  box-shadow: 0px 1px 10px 0px rgba(196, 196, 196, 0.34);
  border-radius: 8px;
  background: #d2ddf5 !important;
}
.questionTxt {
  white-space: pre-wrap !important;
  margin-top: 20px;
  // display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  text-align: left;
  font-size: 20px;
  // display: inline-block;
  .CtxtMenu_Attached_0 {
    font-size: 10px !important;
    color: red;
  }
}

.home {
  margin: 0 auto;
  width: 1200px;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
  background: #333333;
}
</style>
<style >
/* 调整禁用状态下标签的颜色 */
.el-radio.is-disabled .el-radio__label {
  color: #409eff !important; /* 例如，改变标签颜色为灰色 */
}

/* 调整禁用状态下单选框的样式 */
.el-radio.is-disabled .el-radio__input.is-checked .el-radio__inner {
  border-color: #409eff !important; /* 例如，改变选中的边框颜色为灰色 */
}

/* 调整禁用状态下未选中的单选框的样式 */
/* .el-radio.is-disabled .el-radio__input .el-radio__inner {
  border-color: #333 !important;
} */

/* 调整禁用状态下悬停时的样式 */
.el-radio.is-disabled .el-radio__input:hover:not(.is-checked) .el-radio__inner {
  border-color: red !important; /* 例如，改变悬停时的边框颜色为浅灰色 */
}
.quesImg {
  max-width: 650px;
}
</style>

<style lang="less">
@media (max-width: 1000px) {
  .quesImg {
    max-width: 650px !important;
    transform: scale(0.9) !important;
  }
  .home {
    width: 100% !important;
  }
  .cardBig {
    display: none !important;
  }
  .cardSmall {
    display: block !important;
  }
  .marginStyle {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media (max-width: 700px) {
  .imgflexbox {
    display: flex;
    flex-wrap: wrap;
    .imgflexin {
      width: 100% !important;
    }
  }
  .quesImg {
    max-width: 380px !important;
    transform: scale(0.9) !important;
  }
}
</style>
<style>
.MathJax_Display {
  color: blue;
  font-size: 24px;
}
</style>
