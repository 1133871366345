import request from '@/utils/request'


export function userUpDate(data) {
  return request({
    url: '/user/update',
    method: 'post',
    data
  })
}

export function userDetail(query) {
  return request({
    url: '/user/detail',
    method: 'get',
    params: query
  })
}
// export function login(query) {
//   return request({
//     url: '/user/login',
//     method: 'get',
//     params: query
//   })
// }


// export function alarmHandle(data) {
//   return request({
//     url: '/alarm/handle',
//     method: 'post',
//     data
//   })
// }
