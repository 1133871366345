import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
import { ElMessage } from 'element-plus'

import store from '@/store'
// import { useRouter } from 'vue-router'

import { getUserInfo } from '@/utils/auth'

// const router = useRouter()
// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: 'https://ec.longwin.org',
  // baseURL: 'https://iot.kayu-tech.com:8034/monitorPlatform',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    if (getUserInfo()) {
      //   // let each request carry token
      //   // ['X-Token'] is a custom headers key
      //   // please modify it according to the actual situation
      //   // config.headers['Authorization'] = 'Bearer ' + getToken()
      config.headers['token'] = JSON.parse(getUserInfo()).token

      //   // config.headers['X-Token'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if (!res.success) {
      if (res.error.code != 'error.record_ask_continue') {
        ElMessage({
          message: res.error.message || 'Error',
          type: 'error',
          duration: 5 * 1000
        })
        // 
      }

      console.log(res.error.message)
      if (res.error.code == 'invalid token' || res.error.code == 'error.unauthorized') {
        store.dispatch('loginOut')
        // setTimeout(() => {
        //   router.push('/')
        // }, 500)
      }
      return res
      // Promise.reject(new Error(res.error.message || 'Error'))

    } else {

      return res
    }
  },
  // error => {
  //   console.log('err' + error) // for debug
  //   ElMessage({
  //     message: error.error.message,
  //     type: 'error',
  //     duration: 5 * 1000
  //   })
  //   return Promise.reject(error)
  // }
)

export default service
