import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import TestView from "../views/TestView.vue";
import UserCenterView from "../views/UserCenterView.vue";
import loginView from "../views/loginView.vue";
import TestResultView from "../views/TestResultView.vue";
import TestResultDetailView from "../views/TestResultDetailView.vue";
import PapersView from "../views/PapersView.vue";
import HomeWorkView from "../views/HomeWorkView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: '首页',
    },
    component: HomeView,
  },
  {
    path: "/papers",
    name: "papers",
    meta: {
      title: '训练试卷',
    },
    component: PapersView,
  },
  {
    path: "/homework",
    name: "homework",
    meta: {
      title: '课后作业',
    },
    component: HomeWorkView,
  },
  {
    path: "/test",
    name: "test",
    meta: {
      title: '测试',
    },
    component: TestView,
  },
  {
    path: "/result",
    name: "result",
    meta: {
      title: 'AMC8 入营测试结果页',
    },
    component: TestResultView,
  },
  {
    path: "/resultdetail",
    name: "resultdetail",
    meta: {
      title: '试卷结果',
    },
    component: TestResultDetailView,
  },
  {
    path: "/user",
    name: "user",
    meta: {
      title: '个人中心',
    },
    component: UserCenterView,
  },
  {
    path: "/login",
    name: "login",
    meta: {
      title: '登录',
    },
    component: loginView,
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
