import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const UserInfoKey = 'User_Info'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getUserInfo() {
  return Cookies.get(UserInfoKey)
}

export function setUserInfo(info) {
  return Cookies.set(UserInfoKey, info)
}

export function removeUserInfo() {
  return Cookies.remove(UserInfoKey)
}

