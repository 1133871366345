import request from '@/utils/request'


export function classList(query) {
  return request({
    url: '/teacher/class/list',
    method: 'GET',
    params: query
  })
}
export function sclassList(query) {
  return request({
    url: '/student/class/list',
    method: 'GET',
    params: query
  })
}
// export function classDetail(query) {
//   return request({
//     url: '/teacher/class/detail',
//     method: 'GET',
//     params: query
//   })
// }
export function homeworkDetail(query) {
  return request({
    url: '/teacher/class/homework',
    method: 'GET',
    params: query
  })
}
// export function alarmHandle(data) {
//   return request({
//     url: '/alarm/handle',
//     method: 'post',
//     data
//   })
// }
