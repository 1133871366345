<!-- eslint-disable prettier/prettier -->
<template>
  <div style="margin-top: 80px;">
    <el-row style="display: flex;align-items: center;justify-content: center;">
      <el-col :xs="22" :sm="22" :md="10" :lg="10" :xl="10">
        <el-card
          style="padding: 50px 20px;display: flex;flex-direction: column;align-items: center;"
        >
          <div style="margin-bottom: 20px;font-size: 26px;font-weight: 500;">AMC8 入营评估</div>
          <div style="display: flex;flex-direction: column;align-items: flex-start;">
            <div v-if="record" style="display: flex;align-items: center;">
              <span>评估结果：</span>
              <span
                :class="{
                        isYes:record.record.passed,
                        isNo:!record.record.passed
                        }"
                style="font-size: 36px;"
              >{{ record.record.passed?'通过':'未通过' }}</span>
            </div>
            <div v-if="record" style="display: flex;align-items: center;">
              <span>得分：</span>
              <span style="font-size: 32px;color: #95d475;">{{ record.record.score }}</span>
            </div>
            <div v-if="record" style="display: flex;align-items: center;">
              <span>总分：</span>
              <span style="font-size: 30px;">{{ record.score }}</span>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import { recordResultApi } from '@/api/test'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'

export default {
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const record = ref()
    const recordId = route.query._id

    const backPage = () => {
      // router.back()
      router.back()
    }

    onMounted(() => {
      testInit({})
    })
    const testInit = (item) => {
      recordResultApi({
        _id: recordId
      }).then((res) => {
        if (res.success) {
          record.value = res.result
          console.log(record)
        }
      })
    }
    return { record }
  }
}
</script>

<style lang="less">
.isYes {
  color: #95d475;
}
.isNo {
  color: red;
}
</style>




