<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    
    <el-row style="display: flex;align-items: center;justify-content: center;">
      <el-col :xs="23" :sm="23" :md="18" :lg="18" :xl="18">
        <el-card style="min-height: 800px;">
          <div class="box">
            <el-row>
              <el-col
                style="font-size: 20px;margin-bottom: 20px;"
                :xs="24"
                :sm="24"
                :md="6"
                :lg="6"
                :xl="6"
              >
                <img style="width: 80%;" src="../assets/amcbg.png" alt />
                <div style="text-align: left;width: 80%;margin-left: 10%;">
                  <div style="font-weight: 800;font-size: 25px;margin-top: 20px;">2025年AMC8中国区</div>
                  <div style="margin-top: 10px;font-weight: 500;">报名截止时间：</div>
                  <div>2025年1月12日</div>
                  <div style="margin-top: 10px;font-weight: 500;">竞赛时间：</div>
                  <div>2025年1月23日(周五)</div>
                  <div>10:00-10:40(中国时间)</div>
                  <div style="margin-top: 10px;font-weight: 500;">竞赛资格：</div>
                  <div>8年级且14.5岁以下</div>
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
                <div style="text-align: left;margin-bottom: 20px;">
                  <img style="width: 200px;" src="../assets/amctitle.png" alt />
                  <div ref="innerRef" v-if="paperList.length">
                    <div
                      v-for="(item, index) in paperList.slice(0,1)"
                      :key="index"
                      class="type_cardA"
                    >
                      <div>
                        <span v-if="item.name">{{ item.name}}</span>
                      </div>

                      <el-button v-if="item.status =='editing'" type="info" plain disabled>待上线</el-button>

                      <el-button
                        v-else-if="!item.record"
                        @click="toAmcTest(item)"
                        type="primary"
                        class="btn"
                      >去答题</el-button>
                      <el-button
                        v-else-if="item.record&&!item.record.submitAt"
                        class="btn"
                        @click="toContinueResult(item)"
                        type="primary"
                        size="large"
                      >继续评估</el-button>
                      <span
                        class="scoreTxt"
                        style="color:#95d475;"
                        v-if="item.record&&item.record.submitAt&&item.record.passed"
                      >{{item.record.score}} 分</span>
                      <span
                        class="scoreTxt"
                        style="color: red;"
                        v-if="item.record&&item.record.submitAt&&!item.record.passed"
                      >{{item.record.score}} 分</span>
                    </div>
                  </div>
                  <!-- <el-slider
                    v-model="value"
                    :max="max"
                    :format-tooltip="formatTooltip"
                    @input="inputSlider"
                  />-->
                </div>

                <el-row :gutter="20">
                  <el-col
                    v-if="paperList.length&&paperList.length>1"
                    :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="12"
                    :xl="12"
                    style="margin-bottom: 10px;"
                  >
                    <div
                      class="testbg"
                      style="background: #ffb15f;"
                      @click="toPapersList(paperList[1],'#ffb15f','../assets/history.png')"
                    >
                      <img src="../assets/history.png" alt />
                      {{paperList[1].name}}
                    </div>
                  </el-col>
                  <el-col
                    v-if="paperList.length&&paperList.length>2"
                    :xs="24"
                    :sm="24"
                    :md="12"
                    :lg="12"
                    :xl="12"
                    style="margin-bottom: 10px;"
                  >
                    <div
                      class="testbg"
                      style="background: #f96a6a;"
                      @click="toPapersList(paperList[2],'#f96a6a','../assets/running.png')"
                    >
                      <img style="width: 40px;height: 40px;" src="../assets/running.png" alt />
                      {{paperList[2].name}}
                    </div>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="testbg" style="background: #96de4a;" @click="toHomeWork">
                      <img style="width: 40px;height: 40px;" src="../assets/work.png" alt />
                      课后作业
                    </div>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import { setUserInfo, getUserInfo, removeUserInfo } from '@/utils/auth'
import { userUpDate, userDetail } from '@/api/user'
import { login } from '@/api/login'
import { paperlist } from '@/api/home'

import { useStore } from 'vuex'
import { getDictionary } from '@/api/login'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const router = useRouter()
    const store = useStore()
    const paperList = ref([])

    const activeName = ref('first')
    const toContinueResult = (item) => {
      router.push({
        name: 'test',
        query: {
          group: item._id,
          continueConfirmedBool: true,
          recordId: item.record._id
        }
      })
    }
    const toPapersList = (item, bgcolor, bgimg) => {
      if (store.state.info) {
        console.log()
        if (store.state.info.gender) {
          router.push({
            name: 'papers',
            query: { group: item._id, bgcolor: bgcolor, bgimg: bgimg }
          })
        } else {
          router.push({ name: 'user' })
        }
      } else {
        ElMessage.error('请先登录')
        setTimeout(() => {
          router.push('/login')
        }, 500)
      }
    }
    const toHomeWork = () => {
      if (store.state.info) {
        console.log()
        if (store.state.info.gender) {
          router.push({
            name: 'homework'
          })
        } else {
          router.push({ name: 'user' })
        }
      } else {
        ElMessage.error('请先登录')
        setTimeout(() => {
          router.push('/login')
        }, 500)
      }
    }

    const toAmcResult = (item) => {
      router.push({
        name: 'result',
        query: { _id: item.record._id }
      })
    }
    const toAmcTest = (item) => {
      // router.push({ name: 'result' })

      if (store.state.info) {
        console.log()
        if (store.state.info.gender) {
          router.push({ name: 'test', query: { group: item._id } })
        } else {
          router.push({ name: 'user' })
        }
      } else {
        ElMessage.error('请先登录')
        setTimeout(() => {
          router.push('/login')
        }, 500)
      }
    }

    onMounted(() => {
      // max.value = innerRef.value.clientHeight - 660
      nextTick(() => {
        MathJax.typesetPromise()
      })
      paperlist().then((res) => {
        if (res.success) {
          paperList.value = res.result
        }
      })
    })
    return {
      // value,
      // innerRef,
      // scrollbarRef,
      // formatTooltip,
      // scroll,
      // inputSlider,
      activeName,
      toContinueResult,
      toAmcResult,
      toAmcTest,
      toPapersList,
      toHomeWork,
      paperList
    }
  }
}
</script>

<style lang="less" scoped>
.testbg:hover {
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* 水平偏移 垂直偏移 模糊半径 颜色 */
}
.testbg {
  font-size: 28px;
  border-radius: 16px;
  height: 90px;
  text-align: center;
  line-height: 90px;
  color: #fff;
  font-weight: 800;
  position: relative;
  transition: box-shadow 0.3s ease; /* 添加过渡动画使得阴影变化更平滑 */
  img {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 10px;
    left: 10px;
  }
}
.box {
  padding: 20px 0;
  box-sizing: border-box;
}
.titleBox {
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .line {
    width: 4px;
    height: 28px;
    background: #1296db;
    display: inline-block;
    margin-right: 6px;
  }
}
.type_cardA {
  padding: 12px 8px;
  border-bottom: 1px solid #d9d9d9;
  position: relative;
  width: 100%;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // line-height: 120px;
  text-align: center;
  box-sizing: border-box;
}
.scoreTxt {
  font-weight: 500;
  width: 74px;
  text-align: center;
}
// .amc {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   // background: #57a9d4;
// }

@media (max-width: 600px) {
  .type_cardA {
    padding: 12px 4px !important;
    font-size: 15px !important;
  }
}
</style>




<style scoped>
.scrollbar-demo-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  margin: 10px;
  text-align: center;
  border-radius: 4px;
  background: var(--el-color-primary-light-9);
  color: var(--el-color-primary);
}
.el-slider {
  margin-top: 20px;
}
</style>
