<!-- eslint-disable prettier/prettier -->
<template>
  <div style="margin-top: 40px;">
    <el-row style="display: flex;align-items: center;justify-content: center;">
      <!-- <el-col :xs="22" :sm="22" :md="20" :lg="20" :xl="20"> -->
      <el-col :xs="23" :sm="23" :md="18" :lg="18" :xl="18">
        <el-card>
          <div class="box" style="min-height: 800px;">
            <el-tabs
              v-model="activeName"
              style="padding: 0 10px;box-sizing: border-box;"
              @tab-change="handleClick"
            >
              <el-tab-pane v-for="(item, i) in tabs" :key="i" :name="i">
                <template #label>
                  <span class="custom-tabs-label">
                    <span style="font-size: 20px;">{{ item.name }}{{ item.no }}</span>
                  </span>
                </template>
                <el-row :gutter="0">
                  <el-col
                    style="margin-bottom: 20px;"
                    v-for="(e, j) in item.lessons"
                    :key="j"
                    :xs="24"
                    :sm="8"
                    :md="8"
                    :lg="6"
                    :xl="6"
                  >
                    <div style="padding: 10px;box-sizing: border-box;">
                      <div class="type_card">
                        <div class="card_left">
                          <img src="../assets/work.png" alt />
                          作业 {{ j + 1 }}
                        </div>
                        <div v-if="isTeacher" class="card_right">
                          <div>
                            <span>
                              完成
                              <span style="color: red;">{{ e.finishedCount||0 }}人</span>
                            </span>
                            <span style="margin-left: 4px;">
                              未完成
                              <span
                                style="color: red;"
                              >{{ (e.studentCount||0)-(e.finishedCount||0) }}</span> 人
                            </span>
                          </div>
                          <el-button type="primary" @click="showStudent(item._id,e.no)">查看详情</el-button>
                        </div>
                        <div v-if="!isTeacher" class="card_right">
                          <div>共{{e.paper.questionCount}}题</div>
                          <!-- <el-button v-if="!e.record" @click="toAmcTest(e)" type="primary">去完成</el-button> -->
                          <el-button
                            v-if="!e.record"
                            @click="toAmcTest(e)"
                            type="primary"
                            class="btn"
                          >开始作业</el-button>
                          <el-button
                            v-else-if="e.record&&!e.record.submitAt"
                            class="btn"
                            @click="toContinueResult(e)"
                            type="primary"
                            size="large"
                          >继续完成</el-button>
                          <el-button
                            v-if="e.record&&e.record.submitAt"
                            type="primary"
                            @click="toAmcResult(e)"
                          >查看结果</el-button>
                          <!-- ///// -->
                          <!-- <el-button v-if="!item.record" @click="toAmcTest(item)" type="primary">去完成</el-button>
                          <el-button
                            v-if="item.record&&!item.record.submitAt"
                            type="primary"
                            @click="toContinueResult(item)"
                          >继续评估</el-button>
                          -->
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
  <!-- direction="btt" -->
  <el-drawer style="text-align: left;" v-model="drawer" title="作业完成情况" size="80%">
    <el-row :gutter="10">
      <el-col
        :xs="24"
        :sm="12"
        :md="8"
        :lg="6"
        :xl="6"
        v-for="(item, i) in students"
        :key="i"
        :span="8"
      >
        <el-card>
          <div class="card-header">
            <span>{{ item.user.name }}</span>
          </div>
          <div style="display: flex;justify-content: space-between;align-items: center;">
            <div style="display: flex;">
              <div style="width: 80px;">
                <span>答对</span>
                <span
                  style="color: #67c23a;font-size: 26px;font-weight: 500;margin-left: 10px;"
                >{{ item.rightCount }}</span>
              </div>
              <div style="width: 80px;">
                <span>答错</span>
                <span
                  style="color: red;font-size: 26px;font-weight: 500;margin-left: 10px;"
                >{{ item.errorCount }}</span>
              </div>
            </div>
            <el-button
              @click="toUserPager(item)"
              :disabled="!item.submitAt"
              type="primary"
            >{{ item.submitAt? '查看详情' : '未完成'}}</el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- <div style="color: #333;" v-for="(item, i) in students" :key="i">
      <el-card>
        <template #header>
          <div class="card-header">
            <span>{{ item.user.name }}</span>
          </div>
        </template>
      </el-card>
    </div>-->
  </el-drawer>
</template>

<script >
import { ref, onMounted, nextTick, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import { setUserInfo, getUserInfo, removeUserInfo } from '@/utils/auth'

import { classList, sclassList, homeworkDetail } from '@/api/homework'

import { useStore } from 'vuex'
import { getDictionary } from '@/api/login'
import { useRouter, useRoute } from 'vue-router'

export default {
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const students = ref([])
    const drawer = ref(false)

    const isTeacher = JSON.parse(getUserInfo()).roles.indexOf('teacher') > -1
    // console.log(isTeacher)
    const tabs = ref([])
    const activeName = ref(0)
    console.log(JSON.parse(getUserInfo()), 'asdsadsadasdsad')

    const handleClick = (item) => {
      console.log(activeName.value)
    }
    const showStudent = (id, no) => {
      homeworkDetail({
        _id: id,
        no: no
      }).then((res) => {
        if (res.success) {
          drawer.value = true
          students.value = res.result
        }
      })
    }

    const toContinueResult = (item) => {
      router.push({
        name: 'test',
        query: {
          _id: item.paper._id,
          todetail: true,
          recordId: item.record._id
        }
      })
    }
    const toAmcResult = (item) => {
      router.push({
        name: 'resultdetail',
        query: { _id: item.record._id }
      })
    }
    const toAmcTest = (item) => {
      // router.push({ name: 'result' })
      console.log(item)
      if (store.state.info) {
        console.log()
        if (store.state.info.gender) {
          router.push({
            name: 'test',
            query: { _id: item.paper._id, todetail: true }
          })
        } else {
          router.push({ name: 'user' })
        }
      } else {
        ElMessage.error('请先登录')
        setTimeout(() => {
          router.push('/login')
        }, 500)
      }
    }

    const toUserPager = (item) => {
      router.push({ name: 'resultdetail', query: { _id: item._id } })
    }

    onMounted(() => {
      console.log(route.query)
      if (isTeacher) {
        // 获取班级列表
        classList().then((res) => {
          if (res.success) {
            tabs.value = res.result
            if (res.result.length > 0) {
            }
            console.log(tabs.value)
          }
        })
      } else {
        // 获取班级列表
        sclassList().then((res) => {
          if (res.success) {
            tabs.value = res.result
            if (res.result.length > 0) {
            }
            console.log(tabs.value)
          }
        })
      }

      // paperlists({ group: route.query.group }).then((res) => {
      //   if (res.success) {
      //     paperLists.value = res.result
      //   }
      // })
    })
    // imgurl: require('../static/images/19_222949_14.png')

    return {
      drawer,
      activeName,
      students,
      bgcolor: route.query.bgcolor,
      bgimg: route.query.bgimg,
      toUserPager,
      handleClick,
      toContinueResult,
      toAmcResult,
      toAmcTest,
      showStudent,
      isTeacher,
      tabs
    }
  }
}
</script>

<style lang="less" scoped>
.type_card:hover {
  cursor: pointer;
}
.type_card {
  position: relative;
  width: 100%;
  // height: 120px;
  border-radius: 16px;
  text-align: center;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* 水平偏移 垂直偏移 模糊半径 颜色 */
  .card_left {
    font-size: 22px;
    color: #fff;
    flex: 3;
    line-height: 120px;
    background: #96de4a;
  }
  .card_right {
    height: 90px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1.3;
    background: #ffffff;
  }
  img {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  // .btn {
  //   position: absolute;
  //   right: 10px;
  //   bottom: 2px;
  // }
}

@media (max-width: 600px) {
  .type_card {
    font-size: 18px !important;
  }
}
</style>




